<template>
  <div id="app" style="color: #000">
    <!-- <keep-alive>  -->
    <router-view></router-view>
    <!-- </keep-alive> -->
  </div>
</template>

<script>
import { watch } from "less";
import router from "./router"

export default {
  data() {
    return {}
  },
  methods: {},
  components: {
    router,
  },
  created() {
    this.$store.commit("routes/setRoutesList", this.$router.options.routes)
    // if (this.$route.name !== 'index') return localStorage.setItem('userData', this.$verifyToken());
  },
  mounted() {
    // console.log('当前设备类型：', this.$deviceClass);
  },
  computed: {
    routeName() {
      return this.$route.name;
    }
  },
  watch: {
    routeName(newVal) {
      console.log(newVal)
      const validNames = ['login', 'register', 'reset', 'resend', 'paySuccess', 'payError', 'aivoice'];


      if (!validNames.includes(newVal)) {
        return localStorage.setItem('userData', this.$verifyToken());
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-header,
.el-footer {
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  text-align: center;
  // height: 100vh;
  height: var(--full-height);
  border-right: 1px solid var(--border-color);

  .el-logo {
    height: 50px;
    line-height: 50px;
    font-size: 1.6875rem;
    padding: 0 var(--pdl-spacing-lg);
    color: var(--title-color);
    font-weight: var(--pdl-font-weights-bold);
    background-color: var(--asideBtns-color);
    border-bottom: 1px solid var(--border-color);
  }

  .el-navs {
    border-bottom: 1px solid var(--border-color);

    .PageWithSidebarNav:nth-child(1) {
      padding: 0.75rem var(--pdl-spacing-lg);
    }
  }
}

.el-main {
  color: #333;
  text-align: center;
  line-height: 160px;
}

body>.el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>
